import { ekoWebPixel } from '@ekolabs/eko-gallery-react';
import type {
  ShopifyCart,
  ShopifyLineEdges,
  ItemBody,
} from '@framework/api/cart';

type TrackAddToCartArgs = {
  cart: ShopifyCart;
  remove?: boolean;
  removeId?: string | string[];
  addedIds?: ItemBody[];
  lastCart?: ShopifyCart;
};

type CreateProductsArgs = {
  products: ShopifyLineEdges[];
};

type EkoCartItem = {
  id: string;
  variantId: string;
  name: string;
  price: {
    amount: number;
    currency: string;
  };
  quantity: number;
};

const createProducts = ({ products }: CreateProductsArgs): EkoCartItem[] => {
  const ekoProducts = products
    .filter(product => product?.node)
    .map(product => {
      const { node } = product;
      const { merchandise } = node;
      const regPrice = parseFloat(merchandise.priceV2.amount);
      const discount = parseFloat(
        node.discountAllocations[0]?.discountedAmount?.amount ?? '0'
      );
      const productQuant = node.quantity || 1;
      const discountPrice = regPrice - discount / productQuant;

      const ekoProduct = {
        id: merchandise.product.id?.replace('gid://shopify/Product/', ''),
        variantId: merchandise.id?.replace('gid://shopify/ProductVariant/', ''),
        name: merchandise.product.title,
        price: {
          amount: discountPrice,
          currency: merchandise.priceV2.currencyCode,
        },
        quantity: productQuant,
      };
      return ekoProduct;
    });

  return ekoProducts;
};

// Return the <token> part of the "gid://shopify/Cart/<token>>?key=<secret>" full id.
const getCartId = (cartId: string): string =>
  new URL(cartId).pathname.replace('/Cart/', '');

const trackAddToCart = ({
  cart,
  remove = false,
  removeId,
  addedIds,
  lastCart,
}: TrackAddToCartArgs): void => {
  if (cart) {
    let productArray = createProducts({
      products: cart.lines.edges,
    });

    if (addedIds) {
      const ids = addedIds.map(
        i => `gid://shopify/ProductVariant/${i.shopify_variants_id}`
      );
      const addedLines = cart.lines.edges.filter(e =>
        ids.includes(e.node.merchandise.id)
      );

      productArray = createProducts({
        products: addedLines,
      });
    }

    if (remove) {
      const removedItem = lastCart.lines.edges.find(
        e => e.node.id === removeId
      );

      productArray = createProducts({
        products: [removedItem],
      });
    }

    const eventName = remove ? 'pixel.cart.remove' : 'pixel.cart.add';
    ekoWebPixel.track(eventName, {
      cartId: getCartId(cart.id),
      ...productArray[0],
    });
  }
};

export default trackAddToCart;
